<template>
    <tr>
        <td><router-link :to="project.get_absolute_url">{{ project.experiment_title }}</router-link></td>
        
        <td v-if="page === 'home' || page === 'Facility'">
            <p 
                v-for="experiment_pi in project.experiment_pi" 
                v-bind:key="experiment_pi.id"
                v-bind:experiment_pi="experiment_pi">

                <span class="has-text-link" @click="goTo(experiment_pi.get_absolute_url)" style="cursor: pointer">
                    {{ experiment_pi.first_name }} {{ experiment_pi.last_name }}
                </span>
                
                <router-view :key='$route.fullPath' />

            </p>
        </td>
        <!-- <td v-if="page === 'home'">{{ project.keywords }}</td> -->
        <!-- <td v-if="page === 'home'">{{ project.custom_date_added }}</td> -->
        
        <td v-if="page === 'PI' || page === 'home'">
            <p 
                v-for="facility in project.experiment_facility" 
                v-bind:key="facility.id"
                v-bind:facility="facility">

                <span class="has-text-link" @click="goTo(facility.get_absolute_url)" style="cursor: pointer">
                    {{ facility.name }}
                </span>
                
                <router-view :key='$route.fullPath' />
            </p>
        </td>
        
        <td v-if="(page === 'PI' || page === 'Facility' || page === 'home')">
            {{ getYearsOfExperiment() }}
        </td>
    </tr>
</template>

<script>
export default {
    name: "GenericProjectItem",
    props: {
        project: Object,
        page: '',
    },
    methods: {
        goTo(param) {
            this.$router.push({ path: param })
            .then(() => { this.$router.go() })
        },
        getYearsOfExperiment() {
            const start = this.project.start_of_experiment
            const end = this.project.end_of_experiment
            const year = this.project.year_of_experiment
            const getYear = (dateString) => dateString ? dateString.split('-')[0] : null;
            
            const startYear = getYear(start);
            const endYear = getYear(end);

            if (startYear !== null && endYear !== null) {
                return startYear === endYear ? `${startYear}` : `${startYear} - ${endYear}`;
            } else if (start !== null) {
                return `${startYear}`
            } else if (end !== null) {
                return `${endYear}`
            } else if (year !== null) {
                return `${year}`
            } else {
                return ""
            }
        }
    }
}
</script>